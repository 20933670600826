// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1882394307739127808',
    publicVapidKey : 'BDHjNCwUGOXl7cVWv6jycoZRwzwLQlX9jAZiJ3zcVKeKSXvF15rSJA2Mb-CyGmzna4DFU0AtRUfTbV6NW8ISNRk',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板四',
    // appurl:'11.com',
    appurl:'11.com',
    urltype:'1',
  }),
});
